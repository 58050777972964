import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon, Dropdown, Popup, Container } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import DashOrganizationCard from '../../../components/DashOrganizationCard';
import PieChart from './PieChart';
import { __ } from '../../../i18n';
import * as utils from '../../../utils';

const defaultData = [
  { name: 'max', uv: 100, fill: '#e8e8e8' },
  { name: 'min', uv: 0, fill: '#d0ed57' }
];

const typeOptions = organizationType => [
  { key: 0, text: __('All Types'), value: 'all' },
  { key: 1, text: utils.checkIfItIsClientOrSchool(organizationType) === 1 ? __('Student') : __('Client'), value: 'student' },
  { key: 2, text: __('Staff'), value: 'staff' },
  { key: 3, text: __('Admin'), value: 'admin' }
];

@inject('store') @observer
@graphql(gql`query DashboardAdhesionItemQuery ($organizationId: ID!, $groups: [ID], $type: [EntityType]) {
    node(id: $organizationId) @connection(key: "Organization", filter: ["organizationId"]) {
      ... on Organization {
        id: dbId
        groups (limit: 400) {
          nodes {
            id: dbId
            name
          }
        }
        registered: entities(status: WITH_USERS, groups: $groups, type: $type) {
          totalCount
        }
        pending: entities(status: NO_USERS, groups: $groups, type: $type) {
          totalCount
        }
      }
    }
  }
  `, {
  options: ownProps => ({
    fetchPolicy: 'network-only',
    variables: {
    organizationId: ownProps.params.organization_id,
    groups: ownProps.location.query.groups,
    type: ownProps.location.query.type && ownProps.location.query.type.toUpperCase()
    }
    })
  })
export default class DashboardAdhesionItem extends React.Component {
  groupOptions = () => {
    const { isMobile } = this.props;
    const all = { text: isMobile ? __('Groups') : __('All groups'), value: 'all' };
    const groups = this.props.data.node ? this.props.data.node.groups.nodes.map((group) => {
      const name = group.name.length < 20 ? group.name : (<Popup
        trigger={<span>{group.name.substring(0, 20) + '...'}</span>}
        content={<span>{group.name}</span>}
        hideOnScroll
        floating
      />);
      return { text: name, value: group.id.toString() };
    }) : [];

    return [all].concat(groups);
  }

  customTooltip = (percentage) => {
    if (!percentage) return null;

    return (
      <div style={{ maxWidth: '120px' }}>
        <Container textAlign="center">
          <p className="bold" style={{ color: percentage > 0.9 ? '#73bd05' : '#f9bc2e' }}>{__('%s%% of adhesion.', Math.round(percentage * 100))}</p>
        </Container>
      </div>
    );
  }

  renderMobileFilter = () => {
    const { store, parameters } = this.props;

    return (
      <div style={{ marginTop: '10px' }}>
        <Dropdown
          data-action="select-type"
          icon="angle down"
          inline
          scrolling
          style={{ border: '1px solid #E8E8E8', padding: '12px 15px', borderRadius: '200px' }}
          options={typeOptions(store.currentOrganization.type)}
          value={parameters.type || 'all'}
          onChange={(e, { value }) => this.props.onParameterChange('type', value)}
        />
        <span style={{ marginLeft: '10px' }}>
          <Dropdown
            data-action="select-group"
            icon="angle down"
            inline
            scrolling
            style={{ border: '1px solid #E8E8E8', padding: '12px 15px', borderRadius: '200px' }}
            options={this.groupOptions()}
            value={parameters.groups || 'all'}
            onChange={(e, { value }) => this.props.onParameterChange('groups', value)}
          />
        </span>
      </div>
    );
  }

  renderDesktopFilter = () => {
    const { store, parameters } = this.props;

    return (
      <div>
        <Dropdown
          data-action="select-type"
          icon="angle down"
          inline
          scrolling
          style={{ border: '1px solid #E8E8E8', padding: '12px 15px', borderRadius: '200px' }}
          options={typeOptions(store.currentOrganization.type)}
          value={parameters.type || 'all'}
          onChange={(e, { value }) => this.props.onParameterChange('type', value)}
        />
        <span style={{ marginLeft: '10px' }}>
          <Dropdown
            data-action="select-group"
            icon="angle down"
            inline
            scrolling
            style={{ border: '1px solid #E8E8E8', padding: '12px 15px', borderRadius: '200px' }}
            options={this.groupOptions()}
            value={parameters.groups || 'all'}
            onChange={(e, { value }) => this.props.onParameterChange('groups', value)}
          />
        </span>
      </div>
    );
  }

  renderDesktop = (params) => {
    const { pending, registered, stats, percentage, parameters, peopleTotalCount, link } = params;
    return (
      <div>
        {!!pending && !!registered &&
          <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ marginRight: '20px' }}>
              <span style={{ fontSize: '24px', fontWeight: 'bold' }}>{registered.totalCount}</span>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ backgroundColor: '#0069FF', width: '10px', height: '10px', borderRadius: '50%', marginRight: '10px' }} />
                <span style={{ opacity: '.6', textTransform: 'uppercase', color: '#6C6C6C', fontSize: '13px' }}>{__('Joined')}</span>
              </div>
            </div>
            <PieChart stats={stats} percentage={percentage} parameters={parameters} peopleTotalCount={peopleTotalCount} />
            <div style={{ marginLeft: '20px' }}>
              <span style={{ color: '#1D1D1D', fontSize: '24px', fontWeight: 'bold' }}>{pending.totalCount}</span>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ backgroundColor: '#eaeaea', width: '10px', height: '10px', borderRadius: '50%', marginRight: '10px' }} />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <a href={link} style={{ opacity: '.6', textTransform: 'uppercase', color: '#6C6C6C', textDecoration: 'none', fontSize: '13px' }}>{__('Pending')} </a>
                </div>
              </div>
            </div>
          </div>}
      </div>
    );
  }

  renderMobile = (params) => {
    const { isMobile } = this.props;
    const { pending, registered, stats, percentage, parameters, peopleTotalCount, link } = params;
    return (
      <div>
        {!!pending && !!registered &&
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <PieChart isMobile={isMobile} stats={stats} percentage={percentage} parameters={parameters} peopleTotalCount={peopleTotalCount} />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <span style={{ fontSize: '24px', fontWeight: 'bold' }}>{registered.totalCount}</span>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: '7px' }}>
                  <div style={{ backgroundColor: '#0069FF', width: '10px', height: '10px', borderRadius: '50%', marginRight: '10px' }} />
                  <span style={{ opacity: '.6', textTransform: 'uppercase', color: '#6C6C6C', fontSize: '13px' }}>{__('Joined')}</span>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '10px' }}>
                <span style={{ color: '#1D1D1D', fontSize: '24px', fontWeight: 'bold' }}>{pending.totalCount}</span>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: '7px' }}>
                  <div style={{ backgroundColor: '#eaeaea', width: '10px', height: '10px', borderRadius: '50%', marginRight: '10px' }} />
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <a href={link} style={{ opacity: '.6', textTransform: 'uppercase', color: '#6C6C6C', textDecoration: 'none', fontSize: '13px' }}>{__('Pending')} </a>
                  </div>
                </div>
              </div>
            </div>
          </div>}
      </div>
    );
  }

  render() {
    const { store, data, parameters, isMobile } = this.props;

    if ((data.loading && !data.node) || !data.node) {
      return (
        <DashOrganizationCard
          id="DashboardAdhesion"
          key={'adhesion'}
          isMobile={isMobile}
          title={__('Adhesion')}
          loading={data.loading}
        />);
    }

    const { registered, pending } = data.node;
    let stats = [];
    let percentage;
    const peopleTotalCount = (registered && registered.totalCount) + (pending && pending.totalCount);

    if (!!pending && !!registered) {
      percentage = registered.totalCount + pending.totalCount ? (registered.totalCount) / (registered.totalCount + pending.totalCount) : 0;
      stats = defaultData.concat([{ name: 'total', uv: percentage * 100, fill: '#0069FF' }]);
    }

    const link = `/organizations/${store.currentOrganization.id}/entities?status=NO_USERS${parameters.groups !== undefined ?
      `&groupsIds=[${parameters.groups}]` : ''}${parameters.type !== undefined ?
      `&entityType=${parameters.type.toUpperCase()}` : ''}`;

    const params = { pending, registered, stats, percentage, parameters, peopleTotalCount, link };

    return (
      <DashOrganizationCard
        id="DashboardAdhesion"
        key={'adhesion'}
        isMobile={isMobile}
        title={__('Adhesion')}
        loading={data.loading}
        info={<span>{__('Check out interactively the evolution of user adherence. Use the filters on the right to combine the types of profiles (student, staff, admins) with the groups in the organization.')}</span>}
        filter={isMobile ? this.renderMobileFilter() : this.renderDesktopFilter()}
      >
        {isMobile ? this.renderMobile(params) : this.renderDesktop(params)}
      </DashOrganizationCard>
    );
  }
}
