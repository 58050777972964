import React from 'react';
import { Link } from 'react-router';
import { Icon, Table, Popup, Label, Button, Image } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { cloneDeep } from 'lodash';

import TableView from '../../../components/TableView';
import Avatar from '../../../components/Avatar';

import DashOrganizationCard from '../../../components/DashOrganizationCard';

import { __ } from '../../../i18n';
import * as utils from '../../../utils';

@graphql(gql`query DashboardRepliesItem ($organizationId: ID!, $period: Int) {
    node(id: $organizationId) @connection(key: "Organization", filter: ["organizationId"]) {
      ... on Organization {
        id: dbId
        channels: entities(type: [STAFF, ADMIN], invisible: false, limit: 150) {
          edges {
            node {
              fullname
              dbId
              invisible
              picture {
                uri
                id: dbId
                key
              }
              dailyMessagesMetrics(offset: 0, limit: 1, period: $period) {
                directReplyTime
                directReplyMessages
                forwardedReplyTime
                forwardedReplyMessages
              }
              users {
                totalCount
                nodes {
                  fullname
                }
              }
            }
          }
        }
      }
    }
  }
  `, {
  options: ownProps => ({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
    organizationId: ownProps.params.organization_id,
    period: parseInt(ownProps.location.query.limit || 30, 10),
    }
    })
  })

export default class DashboardRepliesItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (this.props.location.query.limit !== nextProps.location.query.limit) || (this.props.params.organization_id !== nextProps.params.organization_id) || (this.props.data.loading !== nextProps.data.loading) || !this.props.data.node;
  }

  renderColumns = [
    __('Channel'),
    __('Average reply time'),
    __('Total messages'),
    __('Responsible')
  ]

  renderRows = (channel, i) => {
    const _23h30 = 23.5 * 60;

    return (<Table.Row style={channel.dailyMessagesMetrics[0].replyTime / 60 >= _23h30 ? { backgroundColor: '#fff6f6' } : {}} key={i}>
      {
        this.renderRowCells(channel, i).map((cell, j) => <Table.Cell key={j}>{cell}</Table.Cell>)
      }
    </Table.Row>);
  }

  renderEntityFullname = ({ to, fullname }) => {
    if (this.props.store.currentEntity.type !== 'ADMIN') return <span>{fullname}</span>;

    return <Link className="bold defaultClspColor" to={to}>{fullname}</Link>;
  }

  renderRowCells = (channel) => {
    const _23h30 = 23.5 * 60;
    const _5h30 = 5.5 * 60;

    return [
      <div>
        <Avatar avatar spaced="right" src={channel.picture && channel.picture.uri} alt={channel.fullname} />
        {
          channel.fullname.length > 18 ?
            <Popup
              trigger={<strong>{this.renderEntityFullname({ to: `/entities/${channel.dbId}`, fullname: channel.fullname.substring(0, 18).trim() + '...' })}</strong>}
              content={channel.fullname}
            />
            :
            <strong>{this.renderEntityFullname({ to: `/entities/${channel.dbId}`, fullname: channel.fullname })}</strong>
        }
      </div>,
      <div>
        <Icon name="circle" style={{ color: channel.dailyMessagesMetrics[0].replyTime / 60 < _5h30 ? '#2db84b' : (channel.dailyMessagesMetrics[0].replyTime / 60 >= _23h30 ? '#d92b2f' : '#f9bc2e') }} />
        <strong>{utils.handleDirectReplyTime(channel.dailyMessagesMetrics[0].replyTime, null, true)}</strong>
      </div>,
      <div>
        {channel.dailyMessagesMetrics[0].directReplyMessages + channel.dailyMessagesMetrics[0].forwardedReplyMessages}
      </div>,
      <div>
        {
          channel.users && channel.users.totalCount ?
            (
              channel.users.totalCount === 1 ?
                channel.users.nodes.map(c => c.fullname)
                :
                [
                  channel.users.nodes[0].fullname,
                  <Popup
                    basic
                    on="click"
                    key={1}
                    content={<div>{channel.users.nodes.slice(1).map(c => <div style={{ padding: '5px' }}>{c.fullname}</div>)}</div>}
                    trigger={<Label circular as={Button} style={{ marginLeft: '10px' }}>+{channel.users.totalCount - 1}</Label>}
                  />
                ]
            )
            :
            null
        }
      </div>
    ];
  }

  render() {
    const { data, isMobile } = this.props;

    if ((data.loading && !data.node) || !data.node) {
      return (
        <DashOrganizationCard
          id="DashboardReplies"
          key={'answers'}
          isMobile={isMobile}
          title={__('Average reply time')}
          loading={data.loading}
        />);
    }

    let { channels } = data.node;

    channels = cloneDeep(channels);
    channels.edges = channels.edges.map((channel) => {
      channel.node.dailyMessagesMetrics = channel.node.dailyMessagesMetrics.map(metric => ({ ...metric, replyTime: utils.calculateReplyTime(metric) }));

      return channel;
    });

    const stats = channels && channels.edges.map(channel => channel.node).filter(channel => !!channel.dailyMessagesMetrics.find(metric => !!metric.replyTime));

    if (stats) {
      stats.sort((a, b) => (a.dailyMessagesMetrics[0].replyTime < b.dailyMessagesMetrics[0].replyTime ? -1 : (a.dailyMessagesMetrics[0].replyTime > b.dailyMessagesMetrics[0].replyTime ? 1 : 0)));
    }


    return (
      <DashOrganizationCard
        id="DashboardReplies"
        key={'answers'}
        isMobile={isMobile}
        info={<span>{__('Track the channels, see how many messages they reply and how long they take to reply.')}</span>}
        title={__('Average reply time')}
      >
        <div style={{ overflowX: 'auto' }}>
          {
            stats && stats.length > 0 ?
              <TableView
                source={stats}
                renderColumns={this.renderColumns}
                renderRows={this.renderRows}
                style={{ marginTop: '20px' }}
              />
              :
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Image src={utils.asset('/images/astronaut.png')} style={{ maxHeight: 260, marginTop: 30 }} centered />
                <p style={{ color: '#1D1D1D', fontSize: 20, margin: '20px 0px 8px' }}>{__('No data found')}</p>
                <p style={{ color: '#00000099' }}>{__('Encourage interactions with the channels so that their times are calculated.')}</p>
                <a href="https://ajuda.classapp.com.br/hc/pt-br/articles/4404932610843-Principais-d%C3%BAvidas-e-melhores-pr%C3%A1ticas-dos-Canais-de-atendimento" rel="noopener noreferrer" target="_blank"><p>{__('See how it works')}</p></a>
              </div>
          }
        </div>
      </DashOrganizationCard>
    );
  }
}
