import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon, Popup } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import DashOrganizationCard from '../../../components/DashOrganizationCard';

import { __ } from '../../../i18n';

@inject('store') @observer
@graphql(gql`query WalleOrganizationReadingRate ($organizationId: ID!, $limit: Int) {
    node(id: $organizationId) {
      ...on Organization {
        id: dbId
        readingRate(limit: $limit) {
          totalCount
        }
      }
    }
  }
  `, {
  options: ownProps => ({
    fetchPolicy: 'network-only',
    variables: {
    organizationId: ownProps.params.organization_id,
    limit: parseInt(ownProps.location.query.limit || 30, 10),
    }
    })
  })
export default class ReadingRateItem extends React.Component {
  render() {
    const { data, isMobile } = this.props;
    if ((data.loading && !data.node) || !data.node) {
      return (<DashOrganizationCard
        id="ReadingRate"
        key={'readingRate'}
        title={__('Reading rate')}
        loading={data.loading}
        isMobile={isMobile}
      />);
    }

    const readingRate = data.node.readingRate.totalCount * 100;

    return (
      <DashOrganizationCard
        id="ReadingRate"
        key={'readingRate'}
        title={__('Reading rate')}
        info={<span>{__('Percentage of the messages that were read, and that were sent by your institution to parents and students in the selected period.')}</span>}
        isMobile={isMobile}
      >
        {
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginTop: '25px' }}>
            <span style={{ fontSize: '30px', fontWeight: 'bold', lineHeight: '30px' }}>{readingRate.toFixed(2)} %</span>
          </div>
        }
      </DashOrganizationCard>
    );
  }
}
