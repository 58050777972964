import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon, Popup, Label } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { __ } from '../../../i18n';
import Modal from '../../../components/Modal';
import DashOrganizationCard from '../../../components/DashOrganizationCard';
import KnowMoreModal from './KnowMoreModal';

const plansConfig = {
  FREE: {
    messagesPerStudent: 5,
    label: __('Free')
  },
  LIGHT_NEW: {
    messagesPerStudent: 10,
    label: __('Light')
  },
  STANDARD_NEW: {
    messagesPerStudent: 30,
    label: __('Standard')
  },
  PREMIUM: {
    label: __('Premium')
  },
  STANDARD: {
    label: __('Standard')
  },
  LIGHT: {
    label: __('Light')
  }
};

@inject('store') @observer
@graphql(gql`query MessageLimitItemQuery ($organizationId: ID!) {
    node(id: $organizationId) {
      ...on Organization {
        id: dbId
        plan
        monthlyMessagesCount
        monthlyMessageQuota
      }
    }
  }
  `, {
  options: ownProps => ({
    fetchPolicy: 'network-only',
    variables: {
    organizationId: ownProps.params.organization_id
    }
    })
  })
export default class MessageLimitItem extends React.Component {
  handleOpenModal = (used, available, scheduledMessages) => {
    this.props.store.appends.push(<KnowMoreModal totalSent={used} scheduledMessages={scheduledMessages} remainingMessages={available} />);
  }

  renderLabel = (quota, available) => {
    const { isMobile } = this.props;

    if (quota) {
      return (
        <div style={{ marginTop: '20px' }}>
          {available > 0 ? <span style={{ fontSize: '30px', fontWeight: 'bold' }}>{available}</span>
            : <span style={{ fontSize: '30px', fontWeight: 'bold', color: '#ff035a' }}>0</span>}
          <span style={{ fontSize: '20px', fontWeight: 'normal', color: '#a5a5a5' }}> / {quota}</span>
        </div>
      );
    }

    return <span style={{ fontWeight: 'bold', marginTop: '20px', fontSize: isMobile ? '20px' : '24px', lineHeight: '30px', letterSpacing: '-0.02em', color: '#6C6C6C' }}>{__('Unlimited messages')}</span>;
  }

  render() {
    const { data, isMobile } = this.props;

    if ((data.loading && !data.node) || !data.node) {
      return null;
    }

    const { monthlyMessagesCount: used, monthlyMessageQuota: quota, plan } = data.node;

    const available = quota - used;
    // const scheduledMessages = messageDrafts.totalCount;

    return (
      <DashOrganizationCard
        id="MessageLimit"
        key={'messageLimit'}
        title={__('Remaining messages')}
        loading={data.loading}
        filter={
          !quota ? <Label style={{ color: '#0069FF', minWidth: isMobile ? '110px' : 'unset', border: '1px solid #E8E8E8', backgroundColor: '#FFFFFF', borderRadius: '20px' }}>
            {__('%s plan', plansConfig[plan].label)}
          </Label>
            : <Popup
              trigger={<Label style={{ color: '#0069FF', border: '1px solid #E8E8E8', backgroundColor: '#FFFFFF', borderRadius: '20px' }}>
                {__('%s plan', plansConfig[plan].label)}
              </Label>}
              content={<p style={{ maxWidth: '300px', display: 'flex', flexDirection: 'column' }}>
                <div>
                  <span>{__('Your plan is')}</span>
                  {' '}
                  <span style={{ color: '#0069FF' }}>{plansConfig[plan].label}</span>
                  {' '}
                  <span>{__('and your quota of messages sent / scheduled is %s', quota)}:</span>
                </div>
                <span style={{ fontWeight: 'bold', marginTop: '15px' }}>{__('%s fixed messages', 1000)}</span>
                <span style={{ fontWeight: 'bold', marginTop: '15px' }}>{__('%s messages per registered student', plansConfig[plan].messagesPerStudent)}</span>
              </p>}
              hideOnScroll
              basic
            />}
      >
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', marginRight: '10px' }}>
          {this.renderLabel(quota, available)}
          {quota && <Popup
            trigger={<span style={{ color: '#0069FF', marginTop: '25px' }}>Saiba mais</span>}
            content={<p style={{ maxWidth: '300px', display: 'flex', flexDirection: 'column' }}>
              <span>{__('The total number of messages sent is the sum of those already sent and scheduled for the current month')}</span>
            </p>}
            hideOnScroll
            basic
          />}
          {/* quota && <span onClick={() => this.handleOpenModal(used, available, scheduledMessages, plansConfig[plan])} style={{ color: '#0069FF', cursor: 'pointer', marginTop: '25px' }}>Saiba mais</span> */}
        </div>
      </DashOrganizationCard>
    );
  }
}
