import React from 'react';
import { Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { __ } from '../../../i18n';
import Modal from '../../../components/Modal';
import PaywallModal from '../../Trial/PaywallModal';

const styles = {
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px'
  },
  confirmButton: {
    backgroundColor: '#0069FF',
    height: '43px',
    color: '#FFF',
    borderRadius: '20px'
  },
  cancelButton: {
    height: '43px',
    borderRadius: '20px'
  },
  content: {
    display: 'flex',
    minWidth: '300px',
    borderRadius: '20px',
    border: '1px solid #E8E8E8',
    padding: '20px'
  }
};

@inject('store')
@observer
export default class KnowMoreModal extends React.Component {

  handleOpenModal = () => {
    this.props.store.appends.push(<PaywallModal />);
  }

  render() {
    const { totalSent, scheduledMessages, remainingMessages } = this.props;
    return (
      <Modal
        size="small"
        closeIcon={'times close inside'}
        header={__('Remaining messages')}
        onClose={() => this.props.store.appends.pop()}
        content={
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
              <div style={styles.content}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <span style={{ fontWeight: 'bold', fontSize: '18px' }}>{__('Use of messages')}</span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '36px' }}>{totalSent}</span>
                      <span style={{ fontWeight: 'normal', fontSize: '13px', textTransform: 'uppercase', color: '#6C6C6C' }}>{__('Total sent')}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '30px' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '36px' }}>{scheduledMessages}</span>
                      <span style={{ fontWeight: 'normal', fontSize: '13px', textTransform: 'uppercase', color: '#6C6C6C' }}>{__('Scheduled Messages')}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div style={styles.content}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <span style={{ fontWeight: 'bold', fontSize: '18px' }}>{__('Your plan')}</span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '36px' }}>{remainingMessages > 0 ? remainingMessages : 0}</span>
                      <span style={{ fontWeight: 'normal', fontSize: '13px', textTransform: 'uppercase', color: '#6C6C6C' }}>{__('Total Remaining Messages')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={styles.footer}>
              <Button
                basic
                style={styles.cancelButton}
                content={__('Close')}
                onClick={() => this.props.store.appends.pop()}
              />
              <Button
                primary
                onClick={() => this.handleOpenModal()}
                style={styles.confirmButton}
                content={__('Upgrade')}
              />
            </div>
          </div>}
      />
    );
  }
}
