import React from 'react';
import { Grid, Segment, Divider, Header, Icon, Button, Popup } from 'semantic-ui-react';
import gql from 'graphql-tag';

import Avatar from '../../../components/Avatar';

import { __ } from '../../../i18n';

export default class DashboardChannelsItem extends React.Component {

  static fragments = {
    organization: gql`
      fragment DashboardChannelsItem on Organization {
        twentyFour: entities(type:STAFF, invisible:false, limit: 150, noReplies: { offset: 1, limit: $limit, interval: DAY }) {
          totalCount
          edges {
            node {
              fullname
              dbId
              noRepliesMessageCount
              picture {
                uri
                id: dbId
                key
              }
              invisible
            }
          }
        }
        six: entities(type:STAFF, invisible:false, limit: 150, noReplies: { offset: 6, limit: 24, interval: HOUR }) {
          totalCount
          edges {
            node {
              fullname
              dbId
              noRepliesMessageCount
              picture {
                uri
                id: dbId
                key
              }
              invisible
            }
          }
        }
      }`
  }

  state = {
    all: false
  };

  toggleShowAll = () => {
    const all = this.state.all;
    this.setState({ all: !all });
  }

  renderItem = (channel, key) => <Grid.Column key={key} style={{ padding: '5px' }}>
    <Segment>
      <div>
        <Avatar avatar spaced="right" src={channel.picture && channel.picture.uri} alt={channel.fullname} /> <strong>{channel.fullname}</strong>
      </div>
      {
        channel.six ?
          <Segment style={{ boxShadow: 'none', fontSize: '.8em' }}>
            <Icon name="circle" color="yellow" />
            {__('%s messages over %sh', channel.six, '6').toUpperCase()}
          </Segment>
          :
          null
      }
      {
        channel.twentyFour ?
          <Segment style={{ boxShadow: 'none', fontSize: '.8em', backgroundColor: '#fff6f6' }}>
            <Icon name="circle" color="red" />
            {__('%s messages over %sh', channel.twentyFour, '24').toUpperCase()}
          </Segment>
          :
          null
      }
    </Segment>
  </Grid.Column>

  render() {
    const { data } = this.props;
    const { six, twentyFour } = data;
    const { all } = this.state;
    const channels = {};

    if (twentyFour && twentyFour.edges) {
      twentyFour.edges.forEach((channel) => {
        channels[channel.node.dbId] = {
          id: channel.node.dbId,
          fullname: channel.node.fullname,
          picture: channel.node.picture,
          twentyFour: channel.node.noRepliesMessageCount
        };
      });
    }

    if (six && six.edges) {
      six.edges.forEach((channel) => {
        if (channels[channel.node.dbId]) {
          channels[channel.node.dbId].six = channel.node.noRepliesMessageCount;
        } else {
          channels[channel.node.dbId] = {
            id: channel.node.dbId,
            fullname: channel.node.fullname,
            picture: channel.node.picture,
            six: channel.node.noRepliesMessageCount
          };
        }
      });
    }

    const stats = Object.keys(channels).map(key => channels[key]);

    if (stats && stats.length) {
      stats.sort((a, b) => b.twentyFour < a.twentyFour ? -1 : (b.twentyFour > a.twentyFour ? 1 : 0));
    }

    return (
      <Grid.Column key={'channels'} className="noPadding">
        <Grid.Row>
          <Segment style={{ border: 'none', padding: '30px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Header as="h5" style={{ textAlign: 'left', fontSize: '.9rem' }}>
                <Header.Content>
                  <Icon name="warning sign" color="blue" />
                  {__('Channels that need attention').toUpperCase()}
                  <Popup
                    trigger={<Icon style={{ opacity: '.6', marginLeft: '5px' }} name="info circle" color="grey" />}
                    content={<span>{__('Monitor channels that need attention, if they have not replied for a long time to a message, talk to them 🙂')}</span>}
                    hideOnScroll
                    basic
                  />
                </Header.Content>
              </Header>
              {
                stats.length > 3 ?
                  <Button content={all ? __('See less') : __('See more')} onClick={() => this.toggleShowAll()} floated="right" />
                  :
                  null
              }
            </div>
            <Divider hidden />
            {
              stats.length > 0 ?
                <Grid container columns={3} style={{ padding: '10px 0', margin: '0px !important' }}>
                  {
                    all ?
                      stats.map((item, key) => this.renderItem(item, key))
                    :
                      stats.slice(0, 3).map((item, key) => this.renderItem(item, key))
                  }
                </Grid>
                :
                <Segment basic secondary padded textAlign="center">
                  {__('There is no data.')}
                </Segment>
            }
          </Segment>
          {/*<Segment
            onClick={() => browserHistory.push(`/dashboard/${this.props.organization_id}/channels`)}
            style={{ height: '50px', cursor: 'pointer' }}
            attached="bottom"
            textAlign="center"
            secondary
          >
            <a>{__('See detailed analysis')}</a>
          </Segment>*/}
        </Grid.Row>
      </Grid.Column>
    );
  }
}
