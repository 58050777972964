import React from 'react';
import { Statistic } from 'semantic-ui-react';
import { RadialBar, RadialBarChart } from 'recharts';

import { __ } from '../../../i18n';

const defaultData = [
  { name: 'max', uv: 100, fill: '#e8e8e8' },
  { name: 'min', uv: 0, fill: '#d0ed57' }
];

export default class PieChart extends React.Component {
  render() {
    const { stats, percentage, parameters, peopleTotalCount, isMobile } = this.props;

    return (
      <div style={{ position: 'relative' }}>
        <RadialBarChart width={isMobile ? 150 : 180} height={isMobile ? 150 : 180} barSize={10} outerRadius={60} innerRadius={60} data={defaultData.concat(stats)}>
          <RadialBar maxAngle={360} clockWise dataKey="uv" />
        </RadialBarChart>
        <div style={{ width: '100%', textAlign: 'center', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}>
          {
            parameters.type === undefined ?
              <div>
                <div>
                  <Statistic size="tiny">
                    <Statistic.Value className="bold" style={{ fontSize: isMobile ? '1.5rem' : '30px' }}>{`${Math.round(percentage * 100)}%`}</Statistic.Value>
                  </Statistic>
                </div>
              </div>
              :
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Statistic size="tiny">
                  <Statistic.Value className={isMobile ? 'bold' : 'bold force-30px'} style={{ fontSize: isMobile ? '1.5rem' : '30px' }}>{`${Math.round(percentage * 100)}%`}</Statistic.Value>
                  <span className="bold" style={{ fontSize: '13px', opacity: '.5', textTransform: 'uppercase', textAlign: 'center', lineHeight: '16.44px', marginTop: '2px' }}>
                    {__('of ') + peopleTotalCount} <br />
                    {(parameters.type === 'student' ? __(' students') : (parameters.type === 'staff' ? __(' staff') : __(' admins')))}
                  </span>
                </Statistic>
              </div>
          }
        </div>
      </div>
    );
  }
}
