import React from 'react';
import { Link } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Grid, Segment, Divider, Header, Icon, Loader, Statistic, Popup, Dimmer } from 'semantic-ui-react';
import { ResponsiveContainer, AreaChart, Area, Tooltip } from 'recharts';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import CustomTooltip from '../../../components/CustomTooltip';
import DashOrganizationCard from '../../../components/DashOrganizationCard';

import { __ } from '../../../i18n';
import * as utils from '../../../utils';

@inject('store') @observer
@graphql(gql`query DashboardSentItemQuery ($organizationId: ID!, $limit: Int!) {
    node(id: $organizationId) @connection(key: "Organization", filter: ["organizationId"]) {
      ... on Organization {
        id: dbId
        sent: messageStats(messageType: RECEIVED, entityTypes: STUDENT, interval: DAY, limit: $limit) {
          totalCount
          nodes {
            count
            date
          }
        }
        entities(type: STUDENT){
          totalCount
        }
      }
    }
  }
  `, {
  options: ownProps => ({
    fetchPolicy: 'network-only',
    variables: {
    organizationId: ownProps.params.organization_id,
    limit: parseInt(ownProps.location.query.limit || 30, 10),
    }
    })
  })
export default class DashboardSentItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (this.props.location.query.limit !== nextProps.location.query.limit) || (this.props.params.organization_id !== nextProps.params.organization_id) || (this.props.data.loading !== nextProps.data.loading) || !this.props.data.node;
  }

  render() {
    const { store, data, isMobile } = this.props;

    if ((data.loading && !data.node) || !data.node) {
      return (
        <DashOrganizationCard
          id="DashboardSentItem"
          key={'sent'}
          title={__('Sent messages')}
          loading={data.loading}
          isMobile={isMobile}
        />);
    }

    const { sent, entities } = data.node;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';
    let stats = [];
    let average = 0;

    if (sent) {
      stats = sent.nodes.map(s => ({
        name: utils.simpleDate(s.date, true, 'L', lang).substring(0, 5),
        messages: s.count + 2,
        offset: 2,
        title: __('Messages')
      })).reverse();

      if (entities && entities.totalCount) {
        average = Math.round((parseInt(sent.totalCount, 10) / parseInt((entities && entities.totalCount) || 0, 10)) * 10) / 10;
      }
    }

    return (
      <DashOrganizationCard
        id="DashboardSentItem"
        key={'sent'}
        title={__('Sent messages')}
        info={<span>{__("Track the progress of your organization's sent messages to students and parents.")}</span>}
        loading={data.loading}
        isMobile={isMobile}
      >
        {
          sent && (
            sent.totalCount > 0 ?
              <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', padding: '0px 20px', marginTop: '20px', maxWidth: '300px' }}>
                <div>
                  <Statistic.Group size="tiny">
                    <Statistic>
                      <Statistic.Value style={{ textAlign: 'left', fontWeight: 'bold' }}>{sent.totalCount}</Statistic.Value>
                      <Statistic.Label style={{ textAlign: 'left', opacity: '.6', fontSize: '.9rem', margin: '5px 0px' }}>{__('Total sent').toUpperCase()}</Statistic.Label>
                    </Statistic>
                    <Statistic>
                      <Statistic.Value style={{ textAlign: 'left', fontWeight: 'bold' }}>{Math.round(average * 10) / 10}</Statistic.Value>
                      <Statistic.Label style={{ textAlign: 'left', opacity: '.6', fontSize: '.9rem', margin: '5px 0px' }}>{__('Average per user').toUpperCase()}</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>
                </div>
                <Divider hidden />
                <ResponsiveContainer width={'100%'} height={110}>
                  <AreaChart data={stats} margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
                    <Area type="monotone" dataKey="messages" stroke="#4a90e2" strokeWidth={4} fillOpacity={1} fill="#f8f8f8" />
                    <Tooltip content={<CustomTooltip />} />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              :
              <Segment basic secondary padded textAlign="center">
                {__('There are no sent messages during this period.')}{' '}
                <Link to={`/entities/${store.currentEntity.id}/messages/add`}>{__('Create a message now.')}</Link>
              </Segment>
          )
        }

        {/*<Segment
            onClick={() => browserHistory.push(`/dashboard/${this.props.organization_id}/sent`)}
            style={{ height: '50px', cursor: 'pointer' }}
            attached="bottom"
            textAlign="center"
            secondary
          >
            <a>{__('See detailed analysis')}</a>
          </Segment>*/}

      </DashOrganizationCard>
    );
  }
}
